import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useDispatch } from 'react-redux';
import Map from '../components/contact/Map';
import ContactFormAndInfo from '../components/contact/ContactFormAndInfo';
import { setScaleIntroAnimation } from '../state/scaleIntroAnim';
import { setIntroStartModule } from '../state/introStartModule';
import { setIntroLoadedOnce } from '../state/introLoadedOnce';
import { setMenuColor } from '../state/menuColor';
import { setIsDropdownMenu } from '../state/isDropdownMenuSlice';
import Seo from '../components/seo/Seo';

const ContactPage = ({ data: { contactData }, pageContext }) => {
	const pageData = contactData?.nodes?.[0] || '';
	const activeLang = (pageContext?.locale === 'uk' ? 'ua' : pageContext?.locale) || '';
	const dispatch = useDispatch();
	const seoGlobal = pageContext?.seoGlobal?.data?.datoCmsSite?.globalSeo || [];
	const seoData = pageData?.seoSettings || '';

	useEffect(() => {
		dispatch(setScaleIntroAnimation(true));
		dispatch(setIntroStartModule(false));
		dispatch(setMenuColor(true));
		dispatch(setIntroLoadedOnce(true));
		if (typeof window !== 'undefined') {
			const width = window?.innerWidth || 0;
			if (width < 1024) {
				dispatch(setIsDropdownMenu(true));
			}
		}
	}, []);
	return (
		<>
			<Seo seoGlobal={seoGlobal} seoData={seoData} />
			<ContactFormAndInfo data={pageData} pageContext={pageContext} activeLang={activeLang} />
			<Map />
		</>
	);
};
export default ContactPage;
export const query = graphql`
	query ($locale: String) {
		contactData: allDatoCmsContactPage(filter: { locale: { eq: $locale } }) {
			nodes {
				textHeader
				seoSettings {
					title
					description
					twitterCard
					image {
						url
					}
				}
			}
		}
	}
`;
