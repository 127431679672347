import React from 'react';
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import PageContainer from '../../common/PageContainer';

const StyledContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	height: 50vh;
	@media (min-width: 768px) {
		height: 100vh;
	}
	margin-top: 50px;
`;

const StyledPin = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.black};
	border-radius: 50%;
	color: ${({ theme }) => theme.colors.white};
	display: flex;
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontWeight.semiBold};
	height: 58px;
	justify-content: center;
	width: 58px;
`;

export default function Map() {
	const defaultProps = {
		center: { lat: 50.07111894421904, lng: 19.892391751671752 },
		zoom: 16.9,
	};

	return (
		<PageContainer className="full-width last">
			<StyledContainer>
				<div style={{ height: '100%', width: '100%' }} data-cursor="hidden-mode">
					<GoogleMapReact
						bootstrapURLKeys={{ key: process.env.GATSBY_GOOGLE_MAP_API_KEY }}
						defaultCenter={defaultProps.center}
						defaultZoom={defaultProps.zoom}
						yesIWantToUseGoogleMapApiInternals
						options={{ mapId: '965700fe970cfb10' }}
					>
						<StyledPin lat={50.07111894421904} lng={19.892391751671752}>
							NOHO
						</StyledPin>
					</GoogleMapReact>
				</div>
			</StyledContainer>
		</PageContainer>
	);
}
