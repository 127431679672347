import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { isMobile } from 'react-device-detect';
import PageContainer from '../../common/PageContainer';
import SocialMediaIcons from '../socialMediaIcons/SocialMediaIcons';
import pureHandleForm from '../../utils/pureHandleForm';
import gotoAnimate from '../../animations/animationsGsap';
import { setIsDropdownMenu } from '../../state/isDropdownMenuSlice';

const StyledContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	font-family: ${({ theme }) => theme.fontFamily.secondary};
	justify-content: space-between;
	@media (min-width: 1366px) {
		min-height: 100vh;
	}
`;
const StyledFormWrapperForCursor = styled.div`
	height: min-content;
	margin-top: 169px;
	width: 100%;
	@media (min-width: 1024px) {
		margin-top: 221px;
		width: 50%;
	}
	&:hover {
		.form {
			pointer-events: auto !important;
		}
	}
`;

const StyledBlock = styled.div`
	width: 100%;
	@media (min-width: 1024px) {
		padding-top: 221px;
		display: flex;
		width: calc(50% - 108px);
	}
	&.form {
		width: 100%;
		margin-bottom: 56px;
		@media (min-width: 1024px) {
			padding-top: 0;
			width: 100%;
			margin-bottom: 0;
		}
	}

	&.for-cursor {
		&.form {
			pointer-events: none;
		}
	}
`;

const StyledInnerBlock = styled.div`
	width: 100%;
`;

const StyledRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	margin-bottom: 31px;
	opacity: 1;

	&.anim {
		position: relative;
		top: 50px;
		visibility: hidden;
		@media (max-width: 1024px) {
			visibility: visible;
		}
	}
	h1 {
		font-family: ${({ theme }) => theme.fontFamily.primary};
		font-size: 55px;
		font-weight: ${({ theme }) => theme.fontWeight.regular};
		line-height: 72px;
		width: 272px;
	}
	:not(:first-of-type) {
		margin-bottom: 49px;
	}
	@media (max-width: 767px) {
		padding-left: 0;
		display: grid;
		grid-template-columns: 1fr;

		h1 {
			font-size: 44px;
			line-height: 60px;
		}
	}
`;

const StyledRowItem = styled.div`
	display: flex;
	flex-wrap: wrap;
	width: 160px;
	@media (max-width: 767px) {
		:first-of-type {
			margin-bottom: 32px;
		}
	}
	@media (min-width: 1024px) {
		&:first-of-type {
			margin-right: 58px;
			width: 100%;
		}
	}
	a {
		cursor: pointer;
		font-size: 16px;
		line-height: 26px;
		&:hover {
			color: ${({ theme }) => theme.colors.lightBrown};
		}
	}
	p {
		line-height: 26px;
	}
`;
const ContactFormAndInfo = ({ data, pageContext, activeLang }) => {
	gsap.registerPlugin(ScrollTrigger);

	const global = pageContext?.globals?.[0] || [];
	const phoneNumber = global?.generalInfoPhoneNumber || '';
	const email = global?.generalInfoEmail || '';
	const address = global?.generalInfoAddress || '';
	const { textHeader } = data;
	const trigger = useRef(null);

	useEffect(() => {
		pureHandleForm(activeLang);
	}, []);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const width = window?.innerWidth || 0;

			const animate = gsap.utils.toArray('.anim');

			if (width > 1024) {
				animate.map((item, i) => {
					gotoAnimate(i, 'text', item);
				});
			}
		}

		const contact = ScrollTrigger.getById('contact') || null;
		return () => {
			contact?.kill();
		};
	}, []);

	return (
		<PageContainer className="content-standard">
			<StyledContainer>
				<StyledFormWrapperForCursor id="form-wrapper-for-cursor" data-cursor="hidden-mode">
					<StyledBlock className={`${!isMobile ? 'for-cursor' : ''} form`} id="contact-form" />
				</StyledFormWrapperForCursor>
				<StyledBlock ref={trigger}>
					<StyledInnerBlock>
						<StyledRow className="anim">
							<h1 data-animation-type-section="text">{textHeader}</h1>
						</StyledRow>
						<StyledRow className="info anim">
							<StyledRowItem className="">
								<a data-animation-type-section="text" href={`tel:${phoneNumber}`} data-cursor="hidden-mode">
									{phoneNumber}
								</a>
								<a data-animation-type-section="text" href={`mailto:${email}`} data-cursor="hidden-mode">
									{email}
								</a>
							</StyledRowItem>
							<StyledRowItem>
								<p>{address}</p>
							</StyledRowItem>
						</StyledRow>
						<StyledRow className="anim">
							<SocialMediaIcons data={global} inContact />
						</StyledRow>
					</StyledInnerBlock>
				</StyledBlock>
			</StyledContainer>
		</PageContainer>
	);
};

export default ContactFormAndInfo;
