const pureHandleForm = (currentLang, message) => {
	const formsOrigin = 'https://noho.onebutton.pl';
	const currentFormId = currentLang === 'pl' ? 'dolnychmlynow10' : currentLang === 'en' ? 'dolnychmlynow10-en' : 'dolnychmlynow10-ru';
	const updateFormHeight = function (formId, data) {
		const iframe = document.getElementById(`iframe-${formId}`);
		if (!iframe) {
			return;
		}

		const { height } = data;
		if (height) {
			iframe.height = `${height + 4}px`;
		}
	};

	const postMessageParameters = function (formId, parameters) {
		const iframe = document.getElementById(`iframe-${formId}`);

		if (!iframe) {
			return;
		}

		parameters = typeof parameters === 'object' && parameters !== null ? parameters : {};
		const message = { id: formId, event: 'set', data: parameters };
		iframe.contentWindow.postMessage(JSON.stringify(message), formsOrigin);
	};

	const handleForm = function (investmentId, formId, container, callback, lazy, branchId) {
		formId = `noho-${formId}`;

		if (container && (container = document.querySelector(container))) {
			const form = document.createElement('DIV');
			form.classList.add('onebutton-form');
			form.style.width = '100%';
			form.innerHTML =
				`<iframe id="iframe-${formId}" ` +
				`src="${formsOrigin}/forms/users/${formId}" ` +
				`scrolling="no" style="display: block; min-height: 400px; width: 100%; ` +
				`max-width: 100%; margin: 0; overflow: hidden; outline: none;" ${
					lazy ? 'loading="lazy" ' : ''
				}frameborder="0" height="560px"></iframe>`;

			container.querySelector('form') && container.querySelector('form').remove();
			container.appendChild(form);
		}

		const iframe = document.getElementById(`iframe-${formId}`);

		if (!iframe) {
			return;
		}

		window.addEventListener(
			'message',
			(event) => {
				if (event.origin !== formsOrigin) {
					return;
				}

				let response = {};
				try {
					response = JSON.parse(event.data);
					if (response.id !== formId) {
						return;
					}
				} catch (e) {
					return;
				}

				const { data } = response;
				if (response.event === 'resize') {
					updateFormHeight(formId, data);
				}
				if (response.event === 'initialized') {
					const parameters = {
						message_hidden: `Strona zapytania:\n${window.location.href}`,
						source_id: 35,
					};
					investmentId && (parameters.investment_id = investmentId);
					branchId && (parameters.branch_id = branchId);
					parameters.message = message === undefined ? '' : message;
					postMessageParameters(formId, parameters);
				}

				if (response.event === 'sent') {
					window.dataLayer && window.dataLayer.push({ event: 'OneButtonForm' }); /* GTM */
					window.fbq && window.fbq('track', 'Lead'); /* FB */
				}
			},
			false,
		);

		if (!callback) {
			return;
		}

		if (document.readyState === 'complete' || document.readyState === 'loaded') {
			callback(formId);
		} else {
			document.addEventListener('DOMContentLoaded', () => {
				callback(formId);
			});
		}
	};
	handleForm(2, currentFormId, '#contact-form', false);
};

export default pureHandleForm;
